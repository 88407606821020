<template>
    <div>
        <ul data-test="locationListOffice" class="location-list">
            <li v-for="(office, officeKey) in offices" :key="officeKey">
                <span class="location location--office">
                    <strong class="location-name">{{office.locationType}}</strong>
                    {{office.locationName}},
                    {{office.address}} {{office.houseNumber}} {{office.houseNumberAdditional}}
                    {{office.zipCode}} {{office.city}}, {{office.country}}
                    <mercur-menu class="edit-facility">
                        <mercur-button class="btn btn-icon"><i class="fas fa-ellipsis-v"></i></mercur-button>
                        <div slot="dropdown">
                            <mercur-item :to="getEditLocationRoute(office)">
                                <i class="fas fa-pen"></i>
                                Edit
                            </mercur-item>
                            <mercur-item :to="getEditLocationHolidaysRoute(office)">
                                <i class="fas fa-umbrella-beach"></i>
                                Change holidays
                            </mercur-item>
                        </div>
                    </mercur-menu>
                </span>
                <ul data-test="locationListFacility" v-if="office.facilities" class="facility-list">
                    <li v-for="(facility, facilityKey) in office.facilities" :key="facilityKey">
                        <span class="location location--facility">
                            <strong class="location-name">{{facility.locationType}}</strong>
                            <span>
                                {{facility.locationName}},
                                {{facility.address}} {{facility.houseNumber}} {{facility.houseNumberAdditional}}
                                {{facility.zipCode}} {{facility.city}}, {{facility.country}}
                            </span>

                            <mercur-menu data-test="locationListEditFacility" class="edit-facility">
                                <mercur-button class="btn btn-icon"><i class="fas fa-ellipsis-v"></i></mercur-button>
                                <div slot="dropdown">
                                    <mercur-item :to="getEditLocationRoute(facility)">
                                        <i class="fas fa-pen"></i>
                                        Edit
                                    </mercur-item>
                                    <mercur-item :to="getEditLocationHolidaysRoute(facility)">
                                        <i class="fas fa-umbrella-beach"></i>
                                        Change holidays
                                    </mercur-item>
                                    <mercur-item :to="getEditLocationContactPersonsRoute(facility)">
                                        <i class="fas fa-address-book"></i>
                                        Change contact persons
                                    </mercur-item>

                                    <mercur-button class="btn btn-white btn-lg btn-full"  @click="triggerDelete(facility)">
                                        <i class="fas fa-trash"></i>
                                        Delete
                                    </mercur-button>

                                </div>
                            </mercur-menu>
                        </span>
                    </li>
                </ul>
                <mercur-button data-test="locationListAddFacility" v-if="isAllowedTo('SupplierCentral/addLocation')" class="btn btn-raised btn-yellow text-uppercase add-facility-button" :to="getAddFacilityRoute(office)">
                    <i class="fas fa-plus"></i>
                    Add new facility
                </mercur-button>
            </li>
        </ul>
        <mercur-dialog :is-open.sync="deleteConfirmationDialogActive">
            Are you sure you want to delete this location?
            <div slot="footer">
                <mercur-button class="btn" @click="deleteConfirmationDialogActive = false" :disabled="loading">Cancel</mercur-button>
                <mercur-button class="btn btn-primary" @click.native="executeDelete()" :disabled="loading">Delete</mercur-button>
            </div>
        </mercur-dialog>

        <mercur-dialog :is-open.sync="this.displayError">
            <h2 class="align-center error_title">Error</h2>
            <span class="error">{{this.errorMessage}}</span>
            <div slot="footer">
                <mercur-button class="btn btn_error" @click="displayError = false" :disabled="loading">OK</mercur-button>
            </div>
        </mercur-dialog>
    </div>
</template>
<script>
import CONFIG from '@root/config'
export default {
    name: 'LocationList',
    props: ['locations'],

    data () {
        return {
            loading: false,
            deleteConfirmationDialogActive: false,
            location: null,
            errorMessage: null,
            displayError: false,
        }
    },

    computed: {
        offices () {
            return this.locations.filter(location => location.locationType === 'OFFICE').map(location => {
                location.facilities = this.locations.filter(loc => loc.parentId === location.locationId)

                return location
            })
        },
    },
    methods: {
        getAddFacilityRoute (office) {
            return '/supplier/' + this.supplierId + '/locations/' + office.locationId + '/add-facility'
        },
        getEditLocationRoute (location) {
            if (location.locationType === 'OFFICE') {
                return {
                    name: 'SupplierEditOffice',
                    params: {
                        supplierId: this.supplierId,
                        officeId: location.locationId,
                    },
                }
            }

            return {
                name: 'SupplierEditFacility',
                params: {
                    supplierId: this.supplierId,
                    facilityId: location.locationId,
                },
            }
        },
        getEditLocationHolidaysRoute (location) {
            return {
                name: 'SupplierEditLocationHolidays',
                params: {
                    supplierId: this.supplierId,
                    locationId: location.locationId,
                },
            }
        },
        getEditLocationContactPersonsRoute (location) {
            return {
                name: 'SupplierEditLocationContactPersons',
                params: {
                    supplierId: this.supplierId,
                    locationId: location.locationId,
                },
            }
        },
        triggerDelete (location) {
            this.location = location
            this.deleteConfirmationDialogActive = true
        },
        executeDelete () {
            const url = CONFIG.API.ROUTES.SUPPLIERS.LOCATION_DELETE
                .replace('{supplierId}', this.supplierId)
                .replace('{locationId}', this.location.locationId)

            if (!this.isAllowedTo('SupplierCentral/deleteLocation')) {
                return
            }

            const payload = {
                location: this.location,
            }
            this.addJob(url)
            this.$api.post(url, payload).then(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Facility successfully deleted.`,
                    type: 'success',
                })
                window.location.reload()
            }).catch(err => {
                if (err.data.message !== undefined) {
                    this.errorMessage = err.data.message
                    this.displayError = true
                    this.$root.$emit('notification:global', {
                        message: err.data.message,
                        type: 'error',
                    })
                }
            }).finally(() => {
                this.finishJob(url)
                this.deleteConfirmationDialogActive = false
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .location-list {
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 0;

        li {
            display: block;
        }
        > li {
            margin-top: 10px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 10px;
                bottom: 20px;
                width: 1px;
                background-color: black;
            }
            &::after {
                bottom: 20px;
                width: 14px;
                background-color: black;
                content: '';
                display: block;
                position: absolute;
                left: 10px;
                height: 1px;
            }
        }
    }

    .location-name {
        padding-right: 20px;
    }

    .facility-list {
        padding-left: 0;
        li {
            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
                display: block;
                content: '';
                width: 15px;
                height: 1px;
                margin-left: 10px;
                background-color: black;
            }
        }
    }

    .location {
        border: 1px solid #dedede;
        display: flex;
        margin-bottom: 8px;
        padding: 10px 10px 10px 15px;
        min-height: 62px;
        flex: 1 0 auto;
        position: relative;
        background-color: white;
        align-items: center;
    }

    .edit-facility {
        margin-left: auto;
    }

    .add-facility-button {
        margin-left: 24px;
        letter-spacing: -0.3px;
    }

    .btn-full {
        margin: 0px;
        width: 100%;
        text-align: left;
    }

    .error_title {
        background-color: #f8d7da;
        padding: 10px;
        border: solid 1px #E7505A;
    }

    .btn_error {
        border: solid 1px #E7505A;
    }
</style>
