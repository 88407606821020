<template>
    <mercur-card v-if="isAllowedTo('SupplierCentral/getLocationsBySupplierId')" class="full-height-layout mx-4 mb-3">
        <div class="flex px-4 justify-content-between align-items-center">
            <h2 class="font-weight-normal">Locations</h2>
            <mercur-button
                v-if="isAllowedTo('SupplierCentral/addLocation')"
                :to="addOfficeRoute"
                class="btn btn-raised btn-yellow text-uppercase">
                <i class="fas fa-plus"></i>
                Add new office
            </mercur-button>
        </div>
        <location-list class="fill" :locations="locations" v-if="locations"></location-list>
        <div v-else-if="locations && locations.length === 0" class="flex align-items-center justify-content-center flex-column">
            <i class="fas fa-search-location no-location-icon"></i>
            <h1>No locations found</h1>
            <h3 class="font-weight-normal mt-0">No locations were found for this supplier</h3>
        </div>
    </mercur-card>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import LocationList from '@/components/LocationList'

export default {
    name: 'LocationsOverview',
    components: { LocationList },

    data () {
        return {
            url: CONFIG.API.ROUTES.SUPPLIERS.LOCATIONS.replace('{supplierId}', this.supplierId),
            locations: null,
        }
    },

    computed: {
        supplierLocations () {
            return this.$store.getters['suppliers/getLocationsBySupplierId'](this.supplierId)
        },
        addOfficeRoute () {
            return '/supplier/' + this.supplierId + '/locations/add-office'
        },
    },

    watch: {
        supplierLocations (locations) {
            if (!locations) {
                return
            }

            this.locations = JSON.parse(JSON.stringify(locations)).map(location => {
                location.locationStatus = 'ACTIVE'
                return location
            })
        },
    },

    created () {
        this.$store.dispatch('suppliers/fetchSupplierLocations', this.supplierId)
    },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables.scss';

.no-location-icon {
    font-size: 7rem;
    color: gray;
}
</style>
